<script setup lang="ts">
import { srcsetDensity } from '~/helpers/useImageSetter'
import type { LocationQuery } from 'vue-router'
import type { Map, Icon, LatLngTuple, MarkerClusterGroup } from 'leaflet'
import type { Locale, SearchHouse } from 'lc-services/types'
import type { transformPrice } from '~/helpers/priceHelper'
import type { StateModal } from '~/types/types'

const BaseMap = () => import('leaflet')
const LeafletMarker = () => import('leaflet.markercluster')

type ExtendedSearchHouse = SearchHouse & {
  id: number
  currentPrice: ReturnType<typeof transformPrice>
  conciergeServiceOffer: string
  iconicCollection: boolean
  isValidPeriod: boolean
  periodValid: boolean
  priceText: string
}

const props = withDefaults(
  defineProps<{
    coords?: { lat: number | string; lng: number | string }
    getHouse?: (houseId: string) => Promise<ExtendedSearchHouse | undefined>
    markerCoords?: {
      gpslatitude: number
      gpslongitude: number
      houseId: number
    }[]
  }>(),
  {
    coords: () => ({ lat: '', lng: '' }),
    getHouse: () => Promise.resolve({} as ExtendedSearchHouse),
    markerCoords: () => [],
  },
)
const emits = defineEmits<{
  'track-house': [houseId: number]
  'click-toggle-wishlist': [value: ExtendedSearchHouse]
}>()

const { t, locale } = useI18n()
const localePath = useLocalePath()
const stateModal = useState<StateModal>('state-modal')
const { isMobile } = useBreakpoint()
const { createNotification } = useNotifications()

const { houseIdsAlreadyInFavorites, deleteHouseWishlist } =
  useMultipleWishlist()

let baseMap: Map
let leaflet: Awaited<ReturnType<typeof BaseMap>>

const attribution =
  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
const circleOptions = ref({
  color: '#202020',
  fillColor: '#202020',
  fillOpacity: 0.6,
  radius: 1000,
  stroke: false,
})
const houseId = ref<number>()
const icon = ref<Icon>()
const mapElement = ref<HTMLDivElement>()
const markersCluster = ref<MarkerClusterGroup>()
const selectedHouse = ref<ExtendedSearchHouse>()
const styleUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
const zoomMap = {
  maxZoom: 14,
  zoom: 12,
  zoomAnimation: false,
  zoomSnap: 0.5,
}

const bounds = computed(() =>
  props.markerCoords.map(
    (hit) => [hit.gpslatitude, hit.gpslongitude] as LatLngTuple,
  ),
)
const isCoordValid = computed(
  () =>
    isLatitude(Number(props.coords.lat)) &&
    isLongitude(Number(props.coords.lng)),
)
const markers = computed(() => ({
  position: {
    lat: parseFloat(props.coords.lat.toString()),
    lng: parseFloat(props.coords.lng.toString()),
  },
}))

watch(
  () => props.markerCoords,
  (_) => {
    if (!markersCluster.value) return
    const layers = markersCluster.value.getLayers()
    if (layers) markersCluster.value.removeLayers(layers)
    setLeafletMap()
  },
)

watch(
  () => houseIdsAlreadyInFavorites.value,
  (value) => {
    const element = document.getElementsByClassName('favorite-button__icon')[0]

    if (element) {
      if (houseId.value && value.includes(houseId.value)) {
        element.innerHTML = filledHeart
      } else {
        element.innerHTML = emptyHeart
      }
    }
  },
)

onMounted(async () => {
  await BaseMap()
    .then((bMap) => {
      leaflet = bMap
      icon.value = leaflet.icon({
        iconUrl:
          'https://cdn.lecollectionist.com/lc/production/assets/images/housemarker.svg',
        iconSize: [40, 40],
      })

      if (mapElement.value) {
        if (props.markerCoords.length === 0) {
          if (isCoordValid.value) {
            baseMap = leaflet
              .map('BaseMap', {
                ...zoomMap,
                minZoom: 7,
                zoomControl: false,
              })
              .setView(
                [markers.value.position.lat, markers.value.position.lng],
                13,
              )

            setLeafletHouse()
          }
        } else {
          baseMap = leaflet
            .map('BaseMap', {
              ...zoomMap,
            })
            .setView([46.90296, 1.90925], 6)
          const stamenToner = leaflet.tileLayer(styleUrl, {
            attribution,
          })

          baseMap.addLayer(stamenToner)

          leaflet.control
            .zoom({
              position: 'bottomright',
            })
            .addTo(toRaw(baseMap))

          setLeafletMap()
        }
      }
      const win = window as any
      win.addToWishlist = addToWishlist
      win.trackHouse = trackHouse
    })
    .catch((err) => {
      console.warn(err)
    })
})

const isLatitude = (lat: number) => isFinite(lat) && Math.abs(lat) <= 90
const isLongitude = (lng: number) => isFinite(lng) && Math.abs(lng) <= 180
const trackHouse = (hId: number) => {
  houseId.value = hId
  emits('track-house', hId)
}
const addToWishlist = (hId: number) => {
  houseId.value = hId

  if (
    houseId.value &&
    houseIdsAlreadyInFavorites.value.includes(houseId.value)
  ) {
    removeHouseWishlist()
  } else if (selectedHouse.value) {
    openModal()
    emits('click-toggle-wishlist', selectedHouse.value)
  }
}

const openModal = () => {
  stateModal.value.multipleWishlistIsOpen = true
}

const removeHouseWishlist = async () => {
  try {
    if (houseId.value && selectedHouse.value) {
      await deleteHouseWishlist(houseId.value)

      createNotification({
        message: t('wishlistMultiple.houseDeleted', {
          houseName: selectedHouse.value.name,
        }),
        showCloseButton: false,
        type: 'secondary',
      })
    }
  } catch (err) {
    console.log('err', err)
  }
}

const setLeafletHouse = () => {
  if (!leaflet || !baseMap) return
  leaflet
    .tileLayer(styleUrl, {
      attribution,
    })
    .addTo(toRaw(baseMap))

  leaflet.control
    .zoom({
      position: 'bottomright',
    })
    .addTo(toRaw(baseMap))

  leaflet
    .circle([markers.value.position.lat, markers.value.position.lng], {
      ...circleOptions.value,
    })
    .addTo(toRaw(baseMap))

  leaflet
    .marker([markers.value.position.lat, markers.value.position.lng], {
      icon: icon.value,
    })
    .addTo(toRaw(baseMap))
}
const baseHouseCard = computed(() =>
  typeof selectedHouse.value !== 'undefined'
    ? templateBaseHouseCard(selectedHouse.value)
    : '',
)
const setLeafletMap = async () => {
  if (!baseMap) return
  baseMap.fitBounds(bounds.value)

  if (!leaflet) return
  try {
    const markerCluster: any = await LeafletMarker()
    markersCluster.value = new markerCluster.MarkerClusterGroup()

    for (let i = 0; i < props.markerCoords.length; i++) {
      const currentMarker = props.markerCoords[i]
      const latLng = new leaflet.LatLng(
        currentMarker.gpslatitude,
        currentMarker.gpslongitude,
      )
      const marker = new leaflet.Marker(latLng, {
        icon: icon.value,
        // @ts-expect-error 'houseId' does not exist in type 'MarkerOptions'
        houseId: currentMarker.houseId,
      })

      marker.on('click', async (e) => {
        if (!leaflet || !baseMap) return
        const res = await props.getHouse(e.target.options.houseId)
        if (res) selectedHouse.value = res

        leaflet
          .popup()
          .setLatLng(latLng)
          .setContent(baseHouseCard.value)
          .openOn(baseMap)
      })

      markersCluster.value?.addLayer(marker)
    }

    if (markersCluster.value) baseMap.addLayer(markersCluster.value)
  } catch (err) {
    console.warn(err)
  }
}

const filledHeart =
  '<svg class="stroke-white fill-white" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" viewBox="0 0 24 24" style="width: 1.5rem; height: 1.5rem;"><path d="M22 8.86222C22 10.4087 21.4062 11.8941 20.3458 12.9929C17.9049 15.523 15.5374 18.1613 13.0053 20.5997C12.4249 21.1505 11.5042 21.1304 10.9488 20.5547L3.65376 12.9929C1.44875 10.7072 1.44875 7.01723 3.65376 4.73157C5.88044 2.42345 9.50794 2.42345 11.7346 4.73157L11.9998 5.00642L12.2648 4.73173C13.3324 3.6245 14.7864 3 16.3053 3C17.8242 3 19.2781 3.62444 20.3458 4.73157C21.4063 5.83045 22 7.31577 22 8.86222Z"></path></svg>'
const emptyHeart =
  '<svg class="stroke-white fill-none" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" viewBox="0 0 24 24" style="width: 1.5rem; height: 1.5rem;"><path d="M22 8.86222C22 10.4087 21.4062 11.8941 20.3458 12.9929C17.9049 15.523 15.5374 18.1613 13.0053 20.5997C12.4249 21.1505 11.5042 21.1304 10.9488 20.5547L3.65376 12.9929C1.44875 10.7072 1.44875 7.01723 3.65376 4.73157C5.88044 2.42345 9.50794 2.42345 11.7346 4.73157L11.9998 5.00642L12.2648 4.73173C13.3324 3.6245 14.7864 3 16.3053 3C17.8242 3 19.2781 3.62444 20.3458 4.73157C21.4063 5.83045 22 7.31577 22 8.86222Z"></path></svg>'

const templateBaseHouseCard = (currentMarker: ExtendedSearchHouse) => {
  const imgSrcset = srcsetDensity(currentMarker.first_photo_url, {
    width: '252',
    aspectRatio: 3 / 2,
  })
  const slug = currentMarker.slug[locale.value as Locale]
  const query: LocationQuery = {}

  if (
    currentMarker.state === 'hidden' &&
    Boolean(currentMarker.private_token)
  ) {
    query['private-token'] = currentMarker.private_token ?? ''
  }

  const housePath = slug
    ? localePath({
        name: 'house-slug',
        params: { slug },
        query,
      })
    : ''

  const wishlistButton = (houseId: number) => {
    if (houseIdsAlreadyInFavorites.value.includes(houseId)) {
      return `<span class="favorite-button__icon">${filledHeart}</span>`
    }

    return `<span class="favorite-button__icon">${emptyHeart}</span>`
  }

  return `
            <div class="house-card max-w-80">
              <a href="${housePath}" onclick="window.trackHouse(${
                currentMarker.id
              })" class="house-card__wrapper" target="${
                isMobile.value ? '_self' : '_blank'
              }">
                <div class="flex flex-col justify-between h-full">
                   <div class="min-h-[198px] overflow-hidden lazy-img__placeholder">
                      <img
                        :alt="${currentMarker.name}"
                        srcset="${imgSrcset}"
                        style="width: 100%; height: 100%; object-fit: cover"
                      >
                    </div>
                    <div class="flex flex-col justify-between h-full p-4 text-center md:text-left">
                      <h6 class="text-xl mb-1">
                        ${currentMarker.name}
                      </h6>
                      <div class="font-bold text-gray-400 text-xs uppercase mb-3">
                        ${currentMarker.destination[locale.value as Locale]}
                      </div>
                      <div class="text-md">
                        <span class="text-gray-600">
                          <strong>${currentMarker.capacity}</strong>
                          ${t('search.capacityKey', currentMarker.capacity)}
                        </span>
                        <span class="text-gray-400 px-2">●</span>
                        <span class="text-gray-600">
                          <strong>${currentMarker.bedrooms}</strong>
                          ${t('search.bedroomsKey', currentMarker.bedrooms)}
                        </span>
                        <span class="text-gray-400 px-2">●</span>
                        <span class="text-gray-600">
                          <strong>${currentMarker.bathrooms}</strong>
                          ${t('search.bathroomsKey', currentMarker.bathrooms)}
                        </span>
                      </div>
                      <div class="border-t border-gray-200 mt-3 pt-3 text-md font-bold">
                        ${currentMarker.currentPrice.text}
                      </div>
                    </div>
                </div>
              </a>
              <div class="house-card__wishlist-button" onclick="window.addToWishlist(${
                currentMarker.id
              })">
                <div class="h-[40px] w-[40px] bg-gray-700 bg-opacity-50 cursor-pointer flex items-center justify-center rounded-full">
                  ${wishlistButton(currentMarker.id)}
                </div>
              </div>
            </div>`
}
</script>

<template>
  <div id="BaseMap" ref="mapElement" class="base-map" />
</template>

<style src="leaflet/dist/leaflet.css"></style>

<style>
.base-map {
  @apply z-10;
}

@screen md {
  .base-map {
    height: 70vh;
  }
}
@screen lg {
  .base-map {
    @apply h-full;
  }
}
.leaflet-container a {
  @apply text-gray-700 no-underline;
}
.base-map .house-card {
  position: relative;
  height: 100%;
  transition: box-shadow 0.3s ease-in-out;
}
.base-map .house-card__wishlist-button {
  z-index: 11;
  transition: opacity 0.5s ease-out;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 40px;
  height: 40px;
}
.base-map .leaflet-popup-content-wrapper {
  @apply rounded-none;
}

.base-map .leaflet-popup-content-wrapper,
.base-map .leaflet-popup-tip {
  @apply border-none;
}

.base-map .leaflet-popup {
  @apply mb-12;
}

.base-map .leaflet-top.leaflet-left,
.base-map .leaflet-popup-close-button {
  @apply hidden;
}

.base-map .leaflet-container a,
.base-map .house-card__wrapper {
  @apply text-gray-700;
}

.base-map .leaflet-popup-content {
  @apply m-0;
}

.base-map .leaflet-popup-content .house-card__wrapper {
  @apply border-0;
}

.base-map .leaflet-marker-icon.marker-cluster {
  @apply p-0 flex items-center justify-center;
}
</style>
